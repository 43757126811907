<template>
  <div>
    <div class="pageview">
      <br />
      <div style="display: flex">
        <div class="te" style="width: 108px">项目名称：</div>
        <div
          class="te"
          style="
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: right;
          "
        >
          {{ list.Name }}
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div class="te">负责人：</div>
        <div class="te">{{ list.Charger }}</div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div class="te">联系电话：</div>
        <div class="te">{{ list.TouchTel }}</div>
      </div>
      <div style="display: flex">
        <div class="te" style="width: 108px">位置：</div>
        <div
          class="te"
          style="
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: right;
          "
        >
          {{ list.Address }}
        </div>
      </div>
      <van-divider />

      <div>
        <div class="te">
          交易名称
          <van-field
            border="true"
            v-model="list.TradeTitle"
            left-icon="comment-o"
            placeholder="请输入交易名称"
          />
        </div>
        <div class="te">金额</div>
        <div style="display: flex; line-height: 35px">
          <!-- <div class="money">￥</div> -->
          <!-- <van-field v-model="dataForm.number"
            type="number"
            ref="inputRef"
            placeholder="请输入应服金额"
            class="input" /> -->
          <!-- <div>
            <input
              type="text"
              v-model="dataForm.money"
              
              class="input"
              placeholder="请输入应服金额"
            />
          </div> -->
          <van-field
            size="large"
            type="number"
            v-model="list.Amount"
            left-icon="gold-coin-o"
            placeholder="请输入金额"
          />
        </div>
      </div>

      <!-- <van-divider /> -->
    </div>

    <div style="text-align: center">
      <van-button
        round
        @click="submit"
        color="#f23030"
        style="width: 85%; margin-top: 10px"
        type="info"
        >确认支付</van-button
      >
    </div>

    <!-- <van-number-keyboard
      v-if="show"
      style="display: block"
      theme="custom"
      v-model="dataForm.money"
      :extra-key="['.']"
      close-button-text="完成"
      @blur="show = false"
      @input="onInput"
      @delete="onDelete"
      @close="close"
    /> -->
  </div>
</template>

<script>
import { GetProject } from "@/api/pay.js";
import Vue from "vue";
import { Toast } from "vant";

Vue.use(Toast);
export default {
  data() {
    return {
      dataForm: {
        money: "",
      },
      show: true,
      list: {},
      TradeTitle: "",
    };
  },
  mounted() { 
    this.getProject();
    // this.$refs.inputRef.focus()
  },
  methods: {
    // 获取项目详情
    async getProject() {
      const res = await GetProject({ projectId: this.$route.params.id });
      console.log(res.data);
      if (res.data.code == 0) {
        this.list = res.data.data;
        // this.list.TradeTitle = this.TradeTitle;
      }
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    submit() {
      if (this.list.TradeTitle == "") {
        Toast("请输入交易名称");
      } else if (this.list.Amount == "") {
        Toast("请输入金额");
      } else {
        console.log(this.list.Amount);
        console.log(this.list.ProjectId);
        this.gotoUrl(
          "/pay?id=" + this.list.ProjectId + "&amount=" + this.list.Amount
        );
      }
    },
  },
};
</script>
<style scoped>
.pageview {
  padding: 0 10px;
  line-height: 30px;
}
.input {
  border: none;
  font-size: 32px;
  font-weight: bold;
  width: 70%;
}
.money {
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
}

.input:placeholder-shown {
  color: azure;
  font-size: 16px;
  font-weight: 400;
}

.te {
  font-size: 16px;
  line-height: 50px;
}

/deep/ .van-cell {
  font-size: 15px;
}

/deep/ .van-cell__value {
  border-bottom: 1px solid #eee;
}

/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  font-size: 24px;
  margin-right: 6px;
}
</style>