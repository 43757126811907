import request from '@/utils/request'

// 获取项目详情，应用于微信扫码支付，通过扫码获取到项目的详情
export function GetProject(query) {
  return request({
    url: '/Project/H5_GetProject',
    method: 'get',
    params: query,
  })
}
